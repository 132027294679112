<template>
    <carousel :per-page="1" :mouse-drag="true" :autoplay="true" :loop="true" :per-page-custom="[[480, 1], [768, 3]]">
        <slide v-for="(item, i) in json" :key="i" :index="i" class="p-0 p-md-1">
            <Card :list="item" @carouselreload="updatePacks"></Card>
        </slide>
    </carousel>
</template>

<script>

    import {Carousel, Slide} from 'vue-carousel';
    import json              from '../../website-data.json';
    import Card              from '../components/Card';

    export default {
        name       : 'CustomCarousel',
        components : {
            Carousel,
            Slide,
            Card
        },
        props      : [],
        data       : function () {
            return {
                json : this.setPacks()
            };
        },
        methods    : {
            setPacks() {
                return json.packs.filter((e) => {
                    return e.pack_route !== this.$route.params.pack_name;
                });
            },
            updatePacks() {
                this.json = this.setPacks();
            }
        },
    };
</script>
